<template>
  <div id="layout-login">
    <div class="content">
      <div class="logo">
        <img
          v-if="$vuetify.theme.dark"
          src="@/assets/logo-white.svg"
          width="216"
          height="50"
          alt="Wepipe Logo"
        />
        <img v-else src="@/assets/logo.svg" alt="Wepipe Logo" />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$route.query) {
      this.$store.commit("setQueryParams", this.$route.query);
    }
  }
};
</script>

<style lang="scss">
#layout-login {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background-1);
  display: flex;
  justify-content: center;
  position: relative;
  padding: 15px;

  @include mobile {
    display: block;
    padding-top: 50px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -1100px;
    right: -1100px;
    width: 2152px;
    height: 2152px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      var(--primary) 0%,
      var(--inherit) 100%
    );
    opacity: 0.25;

    @include mobile {
      content: initial;
    }
  }

  .content {
    background: var(--background-1);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 50px;
    margin: auto 0;
    z-index: 1;
    position: relative;
    width: 100%;
    max-width: 450px;

    @include mobile {
      box-shadow: none;
      padding: 0;
      padding-bottom: 150px;
      margin: 0 auto;
    }

    .logo {
      margin-bottom: 30px;
      img {
        display: block;
        margin: 0 auto;
        height: 38px;
        width: auto;
      }
    }
  }
}
</style>
