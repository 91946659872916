<i18n>
{
  "en": {
    "return": "Go back",
    "input-1": "Code",
    "input-2": "New password",
    "input-3": "Confirm new password",
    "update": "Update password",
    "rule-1": "The code has to be 6 chars or numbers",
    "rule-2": "At least 6 chars",
    "rule-3": "Passwords don't match"
  },
  "pt-BR": {
    "return": "Voltar",
    "input-1": "Código",
    "input-2": "Nova senha",
    "input-3": "Confirme a nova senha",
    "update": "Salvar senha",
    "rule-1": "O código deve possuir 6 caracteres ou números",
    "rule-2": "Mínimo 6 caracteres",
    "rule-3": "As senhas não conferem"
  }
}
</i18n>

<template>
  <div id="password">
    <we-icon-button
      icon="chevron-left"
      :name="$t('return')"
      @click="$router.push({ name: 'code', params: { company: domain } })"
    />
    <v-form
      class="password-fields"
      lazy-validation
      ref="password"
      @submit.prevent="createPassword(recovery)"
    >
      <we-input
        type="text"
        :hideDetails="false"
        class="login"
        :label="$t('input-1')"
        :rules="codeRules"
        mask="XXXXXX"
        hide-details="auto"
        v-model="recovery.password_code"
      />
      <we-input
        class="login"
        :hideDetails="false"
        :label="$t('input-2')"
        type="password"
        hide-details="auto"
        v-model="recovery.password"
      />
      <we-input
        class="login"
        :hideDetails="false"
        :label="$t('input-3')"
        type="password"
        :rules="confirmPasswordRules"
        hide-details="auto"
        v-model="passwordConfirm"
      />
      <we-button
        class="primary"
        :text="$t('update')"
        icon="arrow-right"
        iconPosition="right"
        :loading="loading"
      ></we-button>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      codeRules: [
        password_code =>
          (password_code && password_code.length == 6) || this.$t("rule-1")
      ],
      passwordRules: [
        password => (password && password.length >= 6) || this.$t("rule-2")
      ],
      confirmPasswordRules: [
        confirmPassword =>
          confirmPassword === this.recovery.password || this.$t("rule-3")
      ],
      recovery: {
        password_code: "",
        password: ""
      },
      passwordConfirm: "",
      loading: false
    };
  },
  methods: {
    ...mapActions(["updatePasswordRequest"]),
    back() {
      this.$router.push({ name: "code" });
    },
    async createPassword(recovery) {
      const validated = this.$refs.password.validate();
      if (validated) {
        let formData = new FormData();
        for (var e in recovery) {
          formData.append(e, recovery[e]);
        }
        this.loading = true;
        const response = await this.updatePasswordRequest(formData);
        if (!response.response) {
          this.$router.push({
            name: "login",
            params: { company: this.domain }
          });
        }
        this.loading = false;
      }
    }
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    }
  }
};
</script>

<style lang="scss">
#password {
  width: 100%;
  .we-icon-button {
    position: absolute;
    top: 40px;
    left: 40px;
  }
  .we-button {
    width: 100%;
    max-width: 220px;
    margin: 0 auto;
    margin-top: 16px;
  }
  .has-code {
    font-size: 13px;
    line-height: 19.5px;
    font-style: normal;
    font-weight: $medium;
    color: var(--primary);
    text-align: center;
    cursor: pointer;
  }
}
</style>
