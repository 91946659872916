<i18n>
{
  "en": {
    "domain": "Domain",
    "input-1": "Email",
    "input-2": "Password",
    "action": "Sign in",
    "forgot": "Forgot password",
    "without-account": "No account?",
    "register": "Create one"
  },
  "pt-BR": {
    "domain": "Domínio",
    "input-1": "E-mail",
    "input-2": "Senha",
    "action": "Fazer login",
    "forgot": "Esqueci a senha",
    "without-account": "Ainda não tem uma conta?",
    "register": "Registre-se agora!"
  }
}
</i18n>

<template>
  <v-form
    id="login"
    lazy-validation
    ref="login"
    @submit.prevent="signIn(login)"
  >
    <div class="domain">
      {{ $t("domain") }}: <b> {{ domain }} </b>
    </div>
    <div class="login-fields">
      <we-input
        cy="login-email"
        type="email"
        class="login"
        :label="$t('input-1')"
        is-required
        v-model="login.email"
      />
      <we-input
        cy="login-password"
        class="login"
        :label="$t('input-2')"
        type="password"
        is-required
        v-model="login.password"
      />
      <div class="forgot-pass" @click="forgotPassword()">
        {{ $t("forgot") }}
      </div>
      <we-button
        cy="login-button"
        class="primary"
        :text="$t('action')"
        icon="arrow-right"
        iconPosition="right"
        :loading="loading"
      ></we-button>
    </div>
    <div class="no-account">
      {{ $t("without-account") }}
      <a :href="createAccountLink" target="_blank" rel="noopener">
        {{ $t("register") }}
      </a>
    </div>
    <div class="development-mode" v-if="isDevelopment">
      <i>Ambiente de desenvolvimento</i>
    </div>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      login: {
        password: "",
        email: ""
      },
      loading: false
    };
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV != "production";
    },
    domain() {
      return this.$store.getters.getDomain;
    },
    createAccountLink() {
      return `https://wepipe.com.br/?create_account=true&utm_source=app&utm_medium=login&utm_campaign=wepipe-organic&term=${this.domain}&utm_content=register-account`;
    }
  },
  methods: {
    ...mapActions(["authRequest"]),
    async signIn(login) {
      const validate = this.$refs.login.validate();

      if (validate) {
        this.loading = true;

        login.domain = this.domain;
        let formData = new FormData();

        for (var e in login) {
          formData.append(e, login[e]);
        }
        const logged = await this.authRequest(formData);

        if (logged) {
          this.$router.push({ name: "home" });
        }

        this.loading = false;
      }
    },
    forgotPassword() {
      this.$router.push({ name: "code", params: { company: this.domain } });
    }
  },
  mounted() {
    this.$store.commit("setDomain", this.$route.params.company);
    if (this.$route.query?.email) {
      this.login.email = this.$route.query.email;
    }
  }
};
</script>

<style lang="scss">
#login {
  .domain {
    font-size: 12px;
    color: var(--text-2);
    text-align: center;
    margin-bottom: 38px;
    word-break: break-all;

    b {
      margin-left: 6px;
      font-weight: $semibold;
    }
  }
  .login-fields {
    width: 100%;
    .we-input {
      margin-bottom: 20px;

      @include mobile {
        margin-bottom: 20px;
      }
    }
  }

  .we-button {
    width: 100%;
    max-width: 220px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 28px;
  }

  .forgot-pass {
    font-size: 13px;
    line-height: 19.5px;
    font-style: normal;
    font-weight: $medium;
    color: var(--primary);
    text-align: center;
    cursor: pointer;

    &:hover {
      color: var(--text-1);
    }
  }

  .no-account {
    display: block;
    font-size: 13px;
    text-align: center;
    font-weight: $medium;
    color: var(--text-2);
  }

  .development-mode {
    margin-top: 24px;
    font-size: 14px;
    text-align: center;
    color: var(--text-3);
  }
}
</style>
