<i18n>
{
  "en": {
    "return": "Go back",
    "input": "Email",
    "action": "Get code",
    "already-have": "I already have code"
  },
  "pt-BR": {
    "return": "Voltar",
    "input": "E-mail",
    "action": "Obter código",
    "already-have": "Já tenho um código"
  }
}
</i18n>

<template>
  <div id="code">
    <we-icon-button
      icon="chevron-left"
      :name="$t('return')"
      @click="$router.push({ name: 'login', params: { company: domain } })"
    />
    <v-form lazy-validation ref="code" @submit.prevent="sendEmail(login)">
      <we-input
        type="email"
        class="login"
        :label="$t('input')"
        is-required
        :hide-details="false"
        v-model="login.email"
      />
      <we-button
        class="primary"
        :text="$t('action')"
        icon="arrow-right"
        iconPosition="right"
        :loading="loading"
      ></we-button>
      <div class="has-code" @click="hasCode">{{ $t("already-have") }}</div>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      login: {
        email: ""
      },
      loading: false
    };
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    }
  },
  methods: {
    ...mapActions(["recoveryCodeRequest"]),
    back() {
      this.$router.push({
        name: "login",
        params: { company: this.domain }
      });
    },
    hasCode() {
      this.$router.push({ name: "password" });
    },
    async sendEmail(login) {
      const validated = this.$refs.code.validate();
      if (validated) {
        login.domain = this.domain;
        let formData = new FormData();

        for (var e in login) {
          formData.append(e, login[e]);
        }

        this.loading = true;
        const response = await this.recoveryCodeRequest(formData);
        if (!response?.response?.status) {
          this.$router.push({ name: "password" });
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
#code {
  width: 100%;

  .we-icon-button {
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .we-button {
    width: 100%;
    max-width: 220px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .has-code {
    font-size: 13px;
    line-height: 19.5px;
    font-style: normal;
    font-weight: $medium;
    color: var(--primary);
    text-align: center;
    cursor: pointer;
  }
}
</style>
